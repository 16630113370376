body {
  margin: 0;
  font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

.App {
  text-align: center;
  background: #e2f3f5;
  font-family: 'Roboto', sans-serif;
}
#root {
  margin-top: 0px;
}
.quizContainer{ 
  margin: auto;
  max-width: 900px;
  border: 1px  gray;
  padding-bottom: 10px;
  min-height: 100vh;
}

.OptionsContainer{
  width: 100%;
  padding-bottom: 10px;
}
.OptionBtn{
  height: auto;  
  margin-left: 0.5vh;
  margin-right: 0.5vh;
  margin-top: 0.5vh;
}
.mainImg {
    width: 100%;
  margin-top: 10px;  
  margin-bottom: 10px;  
  pointer-events: none;
}


.App-header {
  background-color: #3d5af1;
  min-height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #e2f3f5;
}

.App-link {
  color: #61dafb;
}
.btn-outline-primary2 {
  background-color: #3d5af1;
  border: solid 1px #3d5af1;  
}
.zui-table {
  width: 100%;
  border: solid 1px #DDEEEE;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  font: normal 15px 'Roboto', sans-serif;
}
.zui-table thead th {
  background-color: #DDEFEF;
  border: solid 1px #DDEEEE;
  color: #336B6B;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
}
.zui-table tbody td {
  border: solid 1px #DDEEEE;
  color: #333;
  background-color: #eeffff;
  padding: 10px;
  text-shadow: 1px 1px 1px #fff;
}
.footer {
  left:0;
  bottom:0;
  right:0;
  min-height: 100%;  
  border-top: 2px solid #0e153a;
  background-color: #0e153a;
  padding-bottom: 1vh;
  color: lightgray;
}

.fa-github {
  color: white;
}
.fa-github:hover {
  color: gray;
  text-decoration: none;
}
.fa {
  
  padding: 10px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
}
.CorrectTable thead th{
  
}


.btn {
  position: relative;

  display: block;
  padding: 0;

  overflow: hidden;

  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
  
  background-color: #3d5af1;
  color: #e2f3f5;
  
  transition: background-color .3s;
}

.btn:hover, .btn:focus {
  background-color: #22d1ee;
}

.btn > * {
  position: relative;
}

.btn span {
  display: block;
  padding: 12px 24px;
}

.btn:before {
  content: "";
  
  position: absolute;
  top: 50%;
  left: 50%;
  
  display: block;
  width: 0;
  padding-top: 0;
    
  border-radius: 100%;
  
  background-color: rgba(236, 240, 241, .3);
  
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.btn:active:before {
  width: 120%;
  padding-top: 120%;
  
  transition: width .2s ease-out, padding-top .2s ease-out;
}




